import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useVehicleImage } from './useVehicleImage';
import { ProductInfo } from '@vwfs-bronson/bronson-react';
import { ErrorNotification } from '../../notifications/error/ErrorNotification';
import { VehicleImageView } from '@cp-shared-8/apis';
import { VehicleDetailsUi } from './vehicle-details-ui/VehicleDetailsUi';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { TFunction } from 'i18next';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useVehicleDetails } from './useVehicleDetails';
import { capitalizeWord } from './utils';

const getImageFallbackUrlTranslationKey = (brand?: string): string => {
    switch (brand) {
        case 'Audi':
            return 'fallback-url.audi';
        case 'SEAT':
            return 'fallback-url.seat';
        case '\u0160KODA':
            return 'fallback-url.skoda';
        case 'Volkswagen':
            return 'fallback-url.vw';
    }
    return 'fallback-url.vw';
};

const VehicleDetailsAndImageUi: React.FC<{
    vin: string;
    encryptedVin: string;
    brand?: string;
    model: string;
    licensePlate?: string;
    defaultView: VehicleImageView;
}> = ({ vin, encryptedVin, brand, model, licensePlate, defaultView }) => {
    const { t } = useTranslation('vehicle-details-and-image');
    const [title, setTitle] = useState(`${brand} ${model}`);
    const [subtitle, setSubtitle] = useState('');
    const {
        data: vehicleImages,
        isLoading: isLoadingVehicleImage,
        loadingError: vehicleImageLoadingError,
    } = useVehicleImage(encryptedVin, defaultView);
    const { data: vehicleDetails, isLoading: isLoadingVehicleDetails } = useVehicleDetails(encryptedVin);

    useEffect(() => {
        if (vehicleDetails) {
            const {
                brand = '',
                modelName = '',
                modelVariant = '',
                transmission = '',
                bodyColorName = '',
                bodyColorFinish = '',
                trim = '',
            } = vehicleDetails;
            setTitle([brand, modelName, modelVariant, trim].filter(Boolean).map(capitalizeWord).join(' '));
            setSubtitle([transmission, bodyColorName, bodyColorFinish].filter(Boolean).map(capitalizeWord).join(' | '));
        }
    }, [vehicleDetails]);

    const getBasicDetails = useCallback((t: TFunction, vin: string, licensePlate?: string): DefinitionListItem[] => {
        return compact([
            licensePlate
                ? {
                      label: t('license-plate'),
                      value: licensePlate,
                      testId: 'vehicle-details-license-plate',
                  }
                : undefined,
            vin
                ? {
                      label: t('vin'),
                      value: vin,
                      testId: 'vehicle-details-vin',
                  }
                : undefined,
        ]);
    }, []);

    const itemList: DefinitionListItem[] = useMemo(
        () => getBasicDetails(t, vin, licensePlate),
        [getBasicDetails, t, vin, licensePlate],
    );

    const getImageSrc = () => {
        const image = vehicleImages?.images?.find((image) => image.view === defaultView);
        const showDefault = vehicleImageLoadingError || (!isLoadingVehicleImage && vehicleImages?.images && !image);
        if (!isLoadingVehicleImage && image) {
            return image.url;
        } else if (showDefault) {
            return t(getImageFallbackUrlTranslationKey(brand));
        }
        return '';
    };

    return (
        <>
            <ProductInfo
                title={title}
                titleSuffix={subtitle}
                media={<img src={getImageSrc()} alt={title} />}
                className={'u-mb u-mt'}
            >
                <div className={'u-mt-large u-hide@s'} />
                <DefinitionListHorizontal list={itemList} testId={'basic-details'} />
                <VehicleDetailsUi details={vehicleDetails} isLoading={isLoadingVehicleDetails} />
            </ProductInfo>
        </>
    );
};

export const VehicleDetailsAndImage: React.FC<{
    vin?: string;
    encryptedVin?: string;
    brand?: string;
    model?: string;
    licensePlate?: string;
    defaultView?: VehicleImageView;
    expired?: boolean;
}> = ({
    vin,
    encryptedVin,
    defaultView = VehicleImageView.EXTERIOR_FRONT_LEFT,
    brand,
    model = '',
    licensePlate,
    expired,
}) => {
    if (!expired && (!vin || !encryptedVin)) return <ErrorNotification testId={'no-connection-error'} />;

    return (
        <VehicleDetailsAndImageUi
            vin={vin}
            encryptedVin={encryptedVin}
            brand={brand}
            model={model}
            licensePlate={licensePlate}
            defaultView={defaultView}
        />
    );
};
