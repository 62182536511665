import React, { useEffect, useState } from 'react';
import base64ToBlob from 'b64-to-blob';
import { saveAs as downloadFileAs } from 'file-saver';
import { Modal } from '@vwfs-bronson/bronson-react';
import { Contract, DownloadDocument, MyDocument } from 'common';
import {
    Notification,
    NotificationStatus,
    Postbox,
    PostboxDocument,
    PostboxFilterProps,
    useAnalyticsActionTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { CpDataApi } from 'cp-xhr';
import {
    defaultTimeFrameFilterKey,
    getContractFilterItems,
    getDocumentTypeFilterItems,
    getTimeFrameFilterItems,
    getValidMyDocuments,
    isContractDocument,
    isInvoiceDocument,
    sortDocuments,
    ValidMyDocument,
} from '../utils';
import { DigitalInvoiceModal } from '../digital-invoice-modal';

type MyDocumentsUiProps = {
    myDocumentsData?: MyDocument[];
    contracts?: Contract[];
    defaultContractNumber?: string;
};

export const MyDocumentsUi: React.FC<MyDocumentsUiProps> = ({ myDocumentsData, contracts, defaultContractNumber }) => {
    const { t, f } = useTranslationWithFormatting('my-documents');
    const [documents] = useState<ValidMyDocument[]>(getValidMyDocuments(myDocumentsData, contracts));
    const [downloadError, setDownloadError] = useState(false);
    const [loadingDocuments, setLoadingDocuments] = useState<{ [key: number]: boolean }>({});

    const [showDigitalInvoiceModal, setShowDigitalInvoiceModal] = useState(false);
    const [digitalInvoiceConfirmed, setDigitalInvoiceConfirmed] = useState(false);
    const [requestedInvoice, setRequestedInvoice] = useState<{ document: ValidMyDocument; index: number } | null>(null);

    const { onAction: onDownload } = useAnalyticsActionTracker('onPostboxDownload');
    const { onAction: onDownloadError } = useAnalyticsActionTracker('onPostboxDownloadError');
    const { onAction: onModalClose } = useAnalyticsActionTracker('onPostboxInvoiceSignUpCancel');
    const { onAction: onModalConfirm } = useAnalyticsActionTracker('onPostboxInvoiceSignUpConfirm');

    useAnalyticsPageViewTracker('postboxInvoiceSignUp', showDigitalInvoiceModal);

    useEffect(() => {
        if (digitalInvoiceConfirmed && requestedInvoice) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            downloadPdfFile(requestedInvoice.document, requestedInvoice.index);
            setShowDigitalInvoiceModal(false);
            setRequestedInvoice(null);
        }
    }, [digitalInvoiceConfirmed]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!myDocumentsData || !contracts) {
        return null;
    }

    const getContractFilterItem = (contract: Contract): React.ReactNode => {
        const { isActive, contractNumber, productType, brand, model, licensePlate } = contract;
        const brandModel = `${brand || ''} ${model || ''}`.trim();
        return (
            <>
                <span>
                    <b>
                        {contractNumber} {isActive ? productType : t('filters.contract-identifier.contract-terminated')}
                    </b>
                </span>
                <br />
                <span>
                    {brandModel}
                    {brandModel && licensePlate ? ', ' : ''}
                    {(licensePlate || '').toUpperCase()}
                </span>
            </>
        );
    };

    const shouldShowDigitalInvoiceModal = (): boolean => {
        return (
            !digitalInvoiceConfirmed &&
            contracts.filter((contract) => contract.isActive).every((contract) => !contract.hasDigitalInvoice)
        );
    };

    const downloadPdfFile = (document: ValidMyDocument, index: number): void => {
        const { _downloadLink, documentType } = document;

        if (!_downloadLink) {
            setDownloadError(true);
            return;
        }

        if (isInvoiceDocument(document) && shouldShowDigitalInvoiceModal()) {
            setShowDigitalInvoiceModal(true);
            setRequestedInvoice({ document, index });
            return;
        }

        setLoadingDocuments({ ...loadingDocuments, [index]: true });
        CpDataApi.get(_downloadLink)
            .then((response) => {
                const pdfContentType = 'application/pdf';
                const downloadDocument: DownloadDocument = response.data;
                const pdfBlob = base64ToBlob(downloadDocument.data, pdfContentType);
                downloadFileAs(pdfBlob, downloadDocument.filename);
                onDownload(documentType);
            })
            .catch((error) => {
                setDownloadError(true);
                onDownloadError(documentType, error);
            })
            .finally(() => {
                setLoadingDocuments({ ...loadingDocuments, [index]: false });
            });
    };

    const onDigitalInvoiceClose = () => {
        setShowDigitalInvoiceModal(false);
        setRequestedInvoice(null);
        onModalClose();
    };

    const onDigitalInvoiceConfirm = () => {
        setDigitalInvoiceConfirmed(true);
        onModalConfirm();
    };

    const filters: PostboxFilterProps = {
        documentTypeFilter: {
            label: t('filters.document-type.label'),
            filterItems: getDocumentTypeFilterItems(documents, t),
            allLabel: t('filters.document-type.all-label'),
        },
        contractIdentifierFilter: {
            label: t('filters.contract-identifier.label'),
            filterItems: getContractFilterItems(contracts, getContractFilterItem),
            allLabel: t('filters.contract-identifier.all-label'),
        },
        timeFrameFilter: {
            label: t('filters.time-frame.label'),
            filterItems: getTimeFrameFilterItems(t),
            allLabel: t('filters.time-frame.all-label'),
        },
    };

    const getDocumentSubject = (document: ValidMyDocument): string => {
        const { contractNumber, documentTypeAbbrev } = document;

        if (isInvoiceDocument(document) || isContractDocument(document))
            return documentTypeAbbrev && contractNumber ? `${documentTypeAbbrev} ${contractNumber}` : '';

        return documentTypeAbbrev || '';
    };

    const mapDocuments = (documents: ValidMyDocument[]): PostboxDocument[] => {
        return sortDocuments(documents)
            .map((document, index) => ({
                documentId: index,
                documentType: document.documentType,
                read: true,
                contractIdentifier: document.contractNumber,
                date: formatCpDate(document.creationDate).toMoment(),
                subject: getDocumentSubject(document),
                onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
                    e?.preventDefault();
                    downloadPdfFile(document, index);
                },
                loading: !!loadingDocuments[index],
            }))
            .filter((document) => !!document.subject);
    };

    const tableProps = {
        documents: mapDocuments(documents),
        tableHeaderColumnLabels: { date: t('date'), subject: t('subject') },
        dateFormatter: (day: string, month: string, year: string) =>
            f(formatCpDate(`${year}-${month}-${day}`).toCpDate(), TranslationFormat.DATE),
        noDocumentsSelectedErrorText: t('no-documents-selected-error'),
    };

    return (
        <>
            <h3>{t('subtitle')}</h3>
            <p>{t('description')}</p>
            {!tableProps.documents.length && (
                <Notification status={NotificationStatus.info} testId={'no-documents-from-api-error'}>
                    {t('no-documents-from-api-error')}
                </Notification>
            )}
            {!!tableProps.documents.length && (
                <Postbox
                    filters={filters}
                    defaultContractIdentifier={defaultContractNumber}
                    defaultTimeFrameFilterKey={defaultTimeFrameFilterKey}
                    table={tableProps}
                    resetButtonText={t('reset')}
                    withPaging
                    itemsPerPage={10}
                />
            )}
            <Modal
                shown={downloadError}
                status="error"
                title={t('document-download-error.headline')}
                buttonConfirmText={t('translation:editable-section-nav.back')}
                onClose={(): void => setDownloadError(false)}
                onConfirm={(): void => setDownloadError(false)}
                testId={'document-download-error'}
            >
                {t('document-download-error.text')}
            </Modal>
            <DigitalInvoiceModal
                shown={showDigitalInvoiceModal}
                onClose={onDigitalInvoiceClose}
                onConfirm={onDigitalInvoiceConfirm}
            ></DigitalInvoiceModal>
        </>
    );
};
