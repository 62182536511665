import React from 'react';
import { Card, Layout } from '@vwfs-bronson/bronson-react';
import { Contract, ProductTypeEN } from '../../../common';
import { ContractSummary, ContractSummaryItemProps } from '@cp-shared-8/frontend-ui';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { ContractDetails } from '../contract-details';
import { InactiveUnpaidAlertNotification, UnpaidAlertNotification } from '../unpaid-alert-notifications';
import { RequestAppointmentForCarReturnNotification } from '../request-appointment-for-car-return-notification/RequestAppointmentForCarReturnNotification';
import { withRequestAppointmentForCarReturn } from '../utils';

type ContractComponentProps = {
    contract: Contract;
    defaultExpanded: boolean;
    setUnpaid: (contractId: string, unpaid: boolean) => void;
};

export const ContractComponent: React.FC<ContractComponentProps> = ({ contract, defaultExpanded, setUnpaid }) => {
    const { t, f } = useTranslationWithFormatting('contracts');
    //const contractDetails = useContractDetails(contract.contractId); to be confirmed
    const {
        isActive,
        contractNumber,
        productType,
        productTypeEN,
        model,
        brand,
        nextDueDate,
        nextDueAmount,
        endDate,
        outstandingBalance,
        terminationType,
        amountsInOverdue,
        litigationState,
    } = contract;

    const title = `${brand} ${model}`;
    const subtitle = `${isActive ? productType : t('contract-terminated')} ${contractNumber}`;
    const noValuePlaceholder = '-';

    const getActiveContractSummaryItems = (): Array<ContractSummaryItemProps> => {
        const isFinancialContract = productTypeEN === ProductTypeEN.FINANCIAL;
        return [
            {
                label: t('next-due-date'),
                testClass: 'next-due-date',
                value: nextDueDate ? f(nextDueDate, TranslationFormat.DATE) : noValuePlaceholder,
            },
            {
                label: t('next-due-amount'),
                testClass: 'next-due-amount',
                value: nextDueAmount ? f(nextDueAmount, TranslationFormat.CURRENCY) : noValuePlaceholder,
            },
            {
                label: t('outstanding-balance'),
                testClass: 'outstanding-balance',
                value: outstandingBalance ? f(outstandingBalance, TranslationFormat.CURRENCY) : noValuePlaceholder,
            },
            {
                label: t('end-date'),
                testClass: 'end-date',
                value: endDate ? f(endDate, TranslationFormat.DATE) : noValuePlaceholder,
            },
        ].filter(
            ({ testClass }) =>
                (testClass !== 'outstanding-balance' && !isFinancialContract) ||
                (testClass !== 'end-date' && isFinancialContract),
        );
    };

    const inactiveContractSummaryItems: Array<ContractSummaryItemProps> = [
        {
            label: t('termination-type'),
            testClass: 'termination-type',
            value: terminationType || noValuePlaceholder,
        },
        {
            label: t('end-date'),
            testClass: 'end-date',
            value: endDate ? f(endDate, TranslationFormat.DATE) : noValuePlaceholder,
        },
    ];

    const contractSummaryItems: Array<ContractSummaryItemProps> = isActive
        ? getActiveContractSummaryItems()
        : inactiveContractSummaryItems;

    const UnpaidAlert = isActive ? (
        <UnpaidAlertNotification contractId={contract.contractId} setUnpaid={setUnpaid} />
    ) : (
        <InactiveUnpaidAlertNotification
            amountsInOverdue={amountsInOverdue}
            litigationState={litigationState}
            contractId={contract.contractId}
            setUnpaid={setUnpaid}
        />
    );

    return (
        <>
            <Card
                element="article"
                expandable
                title={title}
                subtitle={subtitle}
                defaultExpanded={defaultExpanded}
                className={'u-mb'}
                contentLarge={<ContractDetails contract={contract} />}
            >
                <ContractSummary items={contractSummaryItems} />
                <Layout>
                    <Layout.Item default="1/1" s="1/1">
                        {UnpaidAlert}
                    </Layout.Item>
                    {withRequestAppointmentForCarReturn(contract) && (
                        <Layout.Item default="1/1" s="1/1">
                            <RequestAppointmentForCarReturnNotification contractId={contract.contractId} />
                        </Layout.Item>
                    )}
                </Layout>
            </Card>
        </>
    );
};
