import { saveAs } from 'file-saver';

export const downloadFile = (url: string, fileName: string, error?: string) => {
    fetch(url)
        .then((resp) => (resp.status === 200 ? resp.blob() : Promise.reject(error)))
        .then((response) => {
            saveAs(response, fileName);
        })
        .catch(() => error && alert(error));
};
