import { Button, ButtonContainer, Heading, Paragraph } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { downloadFile } from 'utils/download';

export const ContractHandover: React.FC = () => {
    const { t } = useTranslation('contract-handover');

    return (
        <>
            <Heading level={3}>{t('entry-point.subtitle')}</Heading>
            <Paragraph className={'u-mb'}>{t('entry-point.text')}</Paragraph>
            <ButtonContainer center>
                <Button
                    secondary
                    onClick={() => {
                        downloadFile(
                            '/contents/vwfs/HandoverCompany.pdf',
                            'Cessão_de_Posição_Contratual_para_Empresa.pdf',
                        );
                    }}
                    testId="company-form-button"
                >
                    {t('entry-point.buttons.company-form')}
                </Button>
                <Button
                    onClick={() => {
                        downloadFile(
                            '/contents/vwfs/HandoverParticular.pdf',
                            'Cessão-de_Posição_Contratual_para_Particular.pdf',
                        );
                    }}
                    testId="private-form-button"
                >
                    {t('entry-point.buttons.private-form')}
                </Button>
            </ButtonContainer>
        </>
    );
};
